<template>
<div></div>
</template>

<script>

export default {
    name: "Home",

    data() {
        return {
            companyName: null,
        }
    },

    mounted() {
        this.redirectHome();
    },

    methods: {
        redirectHome() {
            if (this.companyName === null) {
                setTimeout(this.redirectHome(), 250);
            } else if (this.companyName === "Cyber Theft Watch") {
                this.$router.push("/priorities");
            } else {
                this.$router.push("/assetviewer");
            }

            return null;
        }
    },

    watch: {
        '$store.state.userinfo.companyname': {
            handler() {
                this.companyName = this.$store.state.userinfo.companyname;
                console.debug("Company name: ", this.companyName);
            },
            deep: true,
            immediate: true,
        },
    }
}
</script>

<style scoped>

</style>
